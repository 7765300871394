import { Routes, Route } from "react-router-dom";
import NavBar from "./navBar";
import Home from "./pages/home";
import Footer from "./components/footer";
import Systems from "./pages/systems";
import ScrollToTop from "./components/ScrollToTop";
import { GlobalProvider } from "./components/useContext";
import About from "./pages/about";
import NotFound from "./pages/notFound";



//salm

function App() {
  return (
    <>
    <GlobalProvider>
     <NavBar/>  

    <ScrollToTop/>
    <Routes>
    <Route path="/" element={<Home/>}/>
    <Route path="/sistemlər/:slug?" element={<Systems/>}/>
    <Route path="haqqımızda" element={<About/>}/>
    <Route path='*' element={<NotFound />}/>    

    
     </Routes>
     <Footer/>
     </GlobalProvider>
    </>
  );
}

export default App;
