import languageJson from  "../components/localeLanguages/languages.json"


class LoopTech{
	constructor(){
		this.languageJson = languageJson
		this.language = "az";
	};

	translate(key){
		return this.languageJson[this.language][key] || "";
	}
};


const looptech = new LoopTech();


export default looptech