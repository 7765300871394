import React from 'react'
// import style scss
import "./style.scss"
// import slick slider 
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

import { partnyorsLogoData } from '../../siteDatas';


function PartnyorsLogo () {   
	const settings = {
		dots: false,
        infinite: true,
        slidesToShow: 7.7,
        slidesToScroll: 1,
        autoplay: true,
        speed: 5000,     
        autoplaySpeed: false,
        cssEase: "linear",
		responsive: [
			{
				breakpoint: 1800,
				settings: {
				  slidesToShow: 6.8,    
				}
			  },
			{
			  breakpoint: 1540,
			  settings: {
				slidesToShow: 5.5,     
			  }
			},
			{
				breakpoint: 1366,
				settings: {
				  slidesToShow: 4.4,
				}
			  },
			{
			  breakpoint: 950,
			  settings: {
				slidesToShow: 3.5,
			  }
			},
			{
			  breakpoint: 700,
			  settings: {
				slidesToShow: 2.7,   
			  }
			},
			{
				breakpoint: 500,
				settings: {
				  slidesToShow: 1.2,     
				}
			  }
		      ]		
	  };     

  return (
	<>
	  <section className='partnyors-section'>      
		<div className='container'>
			<Slider {...settings}>   
             {
				partnyorsLogoData.map(item=>(
					<div key={item.id} className='image'>
            <img src={item.image} alt="partnyor logo" />
                     </div>
				))
			 }

          {/* <div className='image'>
            <img src="assets/partnyorsLogo/logo11.jpg" alt="partnyor logo 11" />    
          </div>
		  <div className='image'>
            <img src="assets/partnyorsLogo/logo12.jpg" alt="partnyor logo 12" />
          </div>
		  <div className='image'>
            <img src="assets/partnyorsLogo/logo13.jpg" alt="partnyor logo 13" />  
          </div>
		  <div className='image'>
            <img src="assets/partnyorsLogo/logo14.jpg" alt="partnyor logo 14" />
          </div>
		  <div className='image'>
            <img src="assets/partnyorsLogo/logo15.jpg" alt="partnyor logo 15" />
          </div>
		  <div className='image'>
            <img src="assets/partnyorsLogo/logo16.jpg" alt="partnyor logo 16" />
          </div>
		  <div className='image'>
            <img src="assets/partnyorsLogo/logo17.png" alt="partnyor logo 17" />
          </div>
		  <div className='image'>
            <img src="assets/partnyorsLogo/logo18.png" alt="partnyor logo 18" />
          </div>
		  <div className='image'>
            <img src="assets/partnyorsLogo/logo19.png" alt="partnyor logo 19" />
          </div> */}
        </Slider>    
		</div>
	  </section>
	</>
  )
}

export default PartnyorsLogo
