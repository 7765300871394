
//     move svg icon

import { ReactComponent as Icon1 } from '../assets/moveSvgIcons/kondisoner.svg';
import { ReactComponent as Icon2 } from '../assets/moveSvgIcons/ventl.svg';
import { ReactComponent as Icon3 } from '../assets/moveSvgIcons/hot.svg';
import { ReactComponent as Icon4 } from '../assets/moveSvgIcons/fire.svg';
import { ReactComponent as Icon5 } from '../assets/moveSvgIcons/electrik.svg';

//     motionless svg icon
import { ReactComponent as MotionIcon1 } from '../assets/motionlessİconsSvg/1.soyutma.svg';
import { ReactComponent as MotionIcon2 } from '../assets/motionlessİconsSvg/2.havalandirma.svg';
import { ReactComponent as MotionIcon3 } from '../assets/motionlessİconsSvg/3.isitme.svg';
import { ReactComponent as MotionIcon4 } from '../assets/motionlessİconsSvg/4.yangin.svg';
import { ReactComponent as MotionIcon5 } from '../assets/motionlessİconsSvg/5.elektrik.svg';

//  bu data istifade olunmadi 
export const MainSectionSliderDatas =[
	{
		id:1,
		image:"/assets/images/mainSlider1.jpg",
		header:"Lorem ipsum dolor sit",
		description:"Lorem ipsum dolor sit, amet consectetur adipisicing elit. Rem, asperiores.",
		descriptionDetailed:"Lorem ipsum dolor sit, amet consectetur adipisicing elit. Rem, asperiores.Lorem ipsum dolor sit, amet consectetur adipisicing elit. Rem, asperiores."
	},
	{
		id:2,
		image:"/assets/images/mainSlider2.jpg",
		header:"Lorem ipsum",
		description:"Lorem ipsum dolor sit, amet consectetur . Rem, asperiores.",
		descriptionDetailed:"Lorem ipsum dolor sit, amet consectetur adipisicing elit. Rem, asperiores.Lorem ipsum dolor sit, amet consectetur adipisicing elit. Rem, asperiores."
	},
	{
		id:3,
		image:"/assets/images/mainSlider3.jpg",
		header:"Lorem ipsum dolor sit Lorem ipsum",
		description:"Lorem ipsum dolor sit, amet consectetur adipisicing",
		descriptionDetailed:"Lorem ipsum dolor sit, amet consectetur adipisicing elit. Rem, asperiores.Lorem ipsum dolor sit, amet consectetur adipisicing elit. Rem, asperiores."
	},
	{
		id:1,
		image:"/assets/images/mainSlider4.jpg",
		header:"Lorem ipsum  dolor",
		description:"Lorem ipsum dolor sit, amet consectetur adipisicing elit.",
		descriptionDetailed:"Lorem ipsum dolor sit, amet consectetur adipisicing elit. Rem, asperiores.Lorem ipsum dolor sit, amet consectetur adipisicing elit. Rem, asperiores."
	},
]


// ===================================
// project section datas
export const projects = [
	{
		id: 1,
		image:"assets/images/ABŞERON PORT.jpg",
		info:"ABŞERON PORT"
	},
	{
		id: 2,
		image:"assets/images/DOST İNKLÜZİV İNKIŞAF VƏ YARADICILIQ MƏRKƏZİ.jpg",
		info:"DOST İNKLÜZİV İNKIŞAF VƏ YARADICILIQ MƏRKƏZİ"
	},
	{
		id: 3,
		image:"assets/images/İBİS OTEL.jpg",
		info:"İBİS OTEL"
	},
	{
		id: 4,
		image:"assets/images/KRISTAL ABŞERONUN YAŞAYIŞ BINALARI.jpg",
		info:"KRISTAL ABŞERONUN YAŞAYIŞ BINALARI"
	},
	{
		id: 5,
		image:"assets/images/NİZAMİ ADINA KİNOTEATR.jpg",
		info:"NİZAMİ ADINA KİNOTEATR"
	},
	{
		id: 6,
		image:"assets/images/CAHAN SƏNAYE KOMPLEKSİ.png",
		info:"CAHAN SƏNAYE KOMPLEKSİ"
	},
	{
		id: 7,
		image:"assets/images/DAYS OTEL.png",
		info:"DAYS OTEL"
	},
	{
		id: 8,
		image:"assets/images/NAXÇIVAN PALACE.jpg",
		info:"NAXÇIVAN PALACE"
	},

	{
		id: 9,
		image:"assets/images/BATIGÖZ KLİNİKASI.jpg",
		info:"BATIGÖZ KLİNİKASI"
	},
	{
		id: 10,
		image:"assets/images/QƏBƏLƏ SƏNAYE KOMPLEKSİ.jpg",
		info:"QƏBƏLƏ SƏNAYE KOMPLEKSİ"
	},
	{
		id: 11,
		image:"assets/images/Kontak Home.jpg",
		info:"KONTAKT HOME"
	},
	{
		id: 12,
		image:"assets/images/LİBRAFF.jpeg",
		info:"LİBRAFF"
	},
	
	{
		id: 13,
		image:"assets/images/VEYSƏLOĞLU.jpg",
		info:"VEYSƏLOĞLU"
	},
]

// partnyorProducts datas
export const PartnyorsProductsSectionDatas =[
	{
		id: 1,
		image:"/assets/images/Daikin RXYQ10U VRV.png",
		description:"Daikin RXYQ10U VRV"
	},
	{
		id: 2,
		image:"/assets/images/Carrier Chiller Soğutma Sistemleri.png",
		description:"Carrier Chiller Soğutma Sistemleri"
	},
	{
		id: 3,
		image: "/assets/images/ezgif-2-14f3a5c819.png",
		description:"Rhoss mərkəzi kondisioner"
	},
	{
		id: 4,
		image:"/assets/images/Kondensatorun hava soyumasıyla Mini-çiller.png",
		description:"Daikin kondensatorun hava soyumasıyla Mini-çiller"    
	},
	{     
		id: 5,
		image:"/assets/images/imagens-pagina-geral-GUF-RD4.png",
		description:"Rekuperator Mitsubishi"
	},
	{     
		id: 6,
		image:"/assets/images/İSTİLİK NASOSU RTWD 265-1140 KW.png",
		description:"İSTİLİK NASOSU RTWD 265-1140 KW"
	},
	{     
		id: 7,
		image:"/assets/images/alize1.png",
		description:"Arikazan avtomatik pelet sobaları arikazan"
	},

]


// our works section,main slider section and systems pages datas      (id,title,body,image,icon,slug)
export const SystemsData = [
	{
		id:1,
		title:"Soyutma sistemləri",
		slug:"Soyutma",
		image:"/assets/siteImages/soyutma sistelmeri.jpg",
		movingIcon: <Icon1/>,
		icon:<MotionIcon1/>,
		description:"VRV sistemi böyük ticarət, sənaye və yaşayış binaları üçün ideal olan yüksək    ", 
		body:"VRV sistemi böyük ticarət, sənaye və yaşayış binaları üçün ideal olan yüksək təkmilləşdirilmiş səmərəli bir kondisioner həllidir. VRV sistemi birdən çox otaq və ya müəyyən zonanın temperaturunu tənzimləmək üçün tək xarici blokdan istifadə edir. Həmçinin o, dəqiq və fərdiləşdirilmiş temperatura nəzarət etməyə imkan verən mərkəzləşdirilmiş sistemlə idarə olunur.\nVRV sistemi yüksək enerji səmərəliliyi üçün də əlverişlidir. Beləki, bu tip sistemlərdə hər otaqda sabit temperatur nəzarətini qoruya bilən dəyişkən soyuducu axınına nəzarət texnologiyasından istifadə olunur.Həmçinin bu tip soyutma sistemləri asan təmirə və hətta lazım olduqda genişləndirmə-dəyişdirilmə xüsusiyyətlərinə də malikdirlər.\nBeləliklə kompakt dizaynı, çevik quraşdırma variantları və qabaqcıl texnologiyası ilə VRV sistemi yüksək performanslı və sərfəli kondisioner həlli axtaran istənilən inşaat üçün ağıllı seçim halına gəlir."
	},
	{
		id:2,
		title:"Havalandırma sistemləri",
		slug:"Havalandırma",
		image:"/assets/siteImages/havalandirma.jpg",
		movingIcon: <Icon2/>,
		icon:<MotionIcon2/>,
		description:"Havalandırma sistemi hər hansı bir binanın istilik, havalandırma və  ",
		body:"Havalandırma sistemi hər hansı bir binanın istilik, havalandırma və kondisioner (HVAC) sisteminin mühüm komponentidir. Onun əsas funksiyası təmiz havanı təmin edib, nəmli, çirkləndirici və digər zərərli maddələri çıxararaq otaqdaxili havanın keyfiyyətini tənzimləməkdir.\nHavalandırma sistemləri təbii ventilyasiya, mexaniki ventilyasiya və hibrid sistemlər də daxil olmaqla müxtəlif növlərə malikdir.\n -	Təbii ventilyasiya sistemləri havanın dövriyyəsi üçün binadakı hava cərəyanlarına və açılışlara quraşdırılır;\n-	Mexaniki ventilyasiya sistemləri havanı binaya daxil və xaric etmək üçün ventilyatorlardan və kanallardan istifadə edir;\n -	Hibrid sistemlər ən səmərəli və effektiv hava mübadiləsini təmin etmək üçün həm təbii, həm də mexaniki ventilyasiya elementlərini özündə birləşdirir.\nYaxşı quraşdırılmış havalandırma sistemi istənilən məkanın hava keyfiyyətini yaxşılaşdırmağa, insanın tənəffüs problemləri riskini azaltmağa və daha sağlam, gümrah yaşayış mühiti təmin etməyə kömək edir."
		
		
	},
	{
		id:3,
		title:"İsitmə sistemləri",
		slug:"İsitmə",
		image:"/assets/siteImages/isitme sistemleri.png",
		movingIcon: <Icon3/>,
		icon:<MotionIcon3/>,
	    description:"Qazanxana suyu qaynama nöqtəsindən aşağı (70-80 c*) bir temperatura qədər  ",
		body:"Qazanxana suyu qaynama nöqtəsindən aşağı (70-80 c*) bir temperatura qədər qızdıraraq yaşayış və digər obyektləri istiliklə təmin etmək üçün xüsusi olaraq hazırlanmış avadanlıq sistemlərindən ibarətdir.\n Buxar Qazanxanalarındakı kimyəvi enerji buxar yaratmaq üçün buradaki suyun qızdırıldığı bir proses vasitəsilə istilik enerjisinə çevrilir. Bu buxar daha sonra lazımi obyektlərə borular vasitəsilə hərəkət edərək, öz istiliyinin bir hissəsini buraxır və yenidən qazanlara qayıdır."
	},
	{
		id:4,
		title:"Yanğın təhlükəsizliyi sistemləri",
		slug:"Yanğın",
		image:"/assets/siteImages/yangin.png",
		movingIcon: <Icon4/>,
		icon:<MotionIcon4/>,
		description:"Yanğınsöndürmə sistemləri bina təhlükəsizliyinin mühüm   ",
		body:"Yanğınsöndürmə sistemləri bina təhlükəsizliyinin mühüm komponentini təşkil edir. Bu sistemlərə adətən yanğın siqnalları, çiləyicilər, yanğın aşkarlayıcıları, yanğın xəbərdarlıq sistemləri və avtomatik alov söndürə bilən digər xüsusi avadanlıqlar daxil edilir.\n Yanğınsöndürmə sistemləri hər bir yanğının xüsusiyyətlərinə tez və effektiv şəkildə cavab verə bilən avtomatlaşdırılmış idarəetmə sisteminə malikdir. İstər kommersiya, istər sənaye, istərsə də yaşayış şəraitində yanğınsöndürmə sistemləri insanları və əmlakı yanğınların dağıdıcı təsirlərindən qorumaq üçün vacib xüsusiyyətlərini daim qoruyub saxlayır."
	},
	{
		id:5,
		title:"Elektrik sistemləri",
		slug:"Elektrik",
		image:"/assets/siteImages/electrik.jpg",
		movingIcon: <Icon5/>,
		icon:<MotionIcon5/>,
		description:"Elektrik sistemləri elektrik enerjisini yaratmaq, ötürmək və   ",
		body:"Elektrik sistemləri elektrik enerjisini yaratmaq, ötürmək və paylamaq üçün xüsusi komponentlərdən ibarət olan mürəkkəb şəbəkələrdir. Bu sistemlər evlərimizin işıqlandırmasından tutmuş, rabitə və əyləncə üçün istifadə etdiyimiz cihazların işləməsi üçün müasir dünyamızda öz vacib yerini tutub. Elektrik sistemləri elektromaqnetizm və elektron axını prinsiplərinə əsaslanaraq, elektrik stansiyalarından tutmuş smartfonlarımızı gücləndirən kiçik mikroçiplərə qədər hər şeydə tapıla bilər. Elektrik sistemləri adətən dörd əsas komponentdən ibarət olur: enerji mənbəyi, yük, keçiricilər və idarəetmə cihazları:\n ●	Enerji mənbəyi generator, batareya və ya elektrik enerjisi istehsal edən digər cihaz şəklində ola bilər.\n ●	Yük, elektrik lampası və ya elektrik mühərriki kimi elektrik enerjisindən istifadə edən bir cihazdır.\n ●	Konduktorlar mis məftillər və ya alüminium kabellər kimi elektrik cərəyanının axmasına imkan verən materiallardan ibarətdir.\n ●	Nəzarət cihazları sistemi idarə etmək və qorumaq üçün istifadə edilir.\n Elektrik sistemləri transformator, kondensator və ya induktorlar kimi müxtəlif növ elektrik komponentlər sinifini də əhatə edə bilər. Sözügedən komponentlər gərginliyin səviyyəsini dəyişdirmək, səs-küy-müdaxiləni süzmək və elektrik enerjisini saxlamaq üçün istifadə edilir."
	},
]

// partnyors logo datas
export const partnyorsLogoData=[
	{
		id:1,
		image:"assets/partnyorsLogo/logo11.jpg" 
	},
	{
		id:2,
		image:"assets/partnyorsLogo/logo12.jpg"
	},
	{
		id:3,
		image:"assets/partnyorsLogo/logo13.jpg"
	},
	{
		id:4,
		image:"assets/partnyorsLogo/logo14.jpg"
	},
	{
		id:5,
		image:"assets/partnyorsLogo/logo15.jpg"
	},
	{
		id:6,
		image:"assets/partnyorsLogo/logo16.jpg"
	},
	{
		id:7,
		image:"assets/partnyorsLogo/logo17.png"
	},
	{
		id:8,
		image:"assets/partnyorsLogo/logo18.png"
	},
	{
		id:9,
		image:"assets/partnyorsLogo/logo19.png"    
	}  
]

// about data section datas
export const AboutSectionDatasAbout=[
	{
		id:1,
		image:"/assets/NTlogo&location/NT-Logo-Blue.svg",
		title:"HAQQIMIZDA",
		body:"2018-ci ildən firmamız havalandırma, kondisioner, isitmə və soyutma sistemləri ilə bağlı hərtərəfli xidmətlər təklif edir. Xidmətlərimiz ofislər, evlər,fitnes mərkəzləri,ticarət binaları, zavod və fabriklər daxil olmaqla müxtəlif strukturların dizaynını, quraşdırılmasını və texniki xidmətini əhatə edir. Biz yüksək keyfiyyətli həllər təqdim etmək üçün beynəlxalq standartlara cavab verən birinci dərəcəli brendlərlə əməkdaşlıq edirik. Bizim əsas məqsədimiz müştərilərimizin gözləntilərini qarşılamaq və üstələməkdir." 
	}
]

export const AboutSectionDatasBG=[
		{
			id:1,
			title:"2018 ci ildən",
			description: "AZƏRBAYCANDA QEYDİYYATA ALINIB"
		},
		{
			id:2,
			title:"125 LAYİHƏ",
			description: "UĞURLA TƏHVİL VERİLİB"
		},
		{
			id:1,
			title:"5 İSTİQAMƏT",
			description: "ÜZRƏ FƏALİYYƏT GÖSTƏRİRİK"
		}
];

