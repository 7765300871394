import React, { useContext } from 'react'
// import style scss
import "./style.scss"
     
// import link 
import { Link } from 'react-router-dom'
// import react icons
import {HiOutlineLocationMarker } from 'react-icons/hi';
import {BsTelephone } from 'react-icons/bs';
import { GlobalContext } from '../useContext';
import looptech from '../../helpers/looptech';


export  const scrollToProjects =()=>{
    const projectSectionElement =  document.getElementById("projects");
    if(!projectSectionElement){
        sessionStorage.setItem("scrollToProjects","true");
       return window.location.href ="/";
    }
    projectSectionElement.scrollIntoView({behavior:"smooth",block:"center"})
}

function Footer() {
 
   // useContext
   useContext(GlobalContext) 
      

  return (
	<>
	<section className='footer-section'>    
		<div className='container'>
			<div className='footer'>
				<div className='footer-up'>
				
                <a className='logo' href="/">
				<img src="/assets/NTlogo&location/NT-Logo-White.png" alt="burada NT logosu var" />
				</a>
				<div className='pages-adress-call'>
					<ul className="pages">
				            <li>
                                <a href="/" >{looptech.translate("home")}</a>  
                            </li>
                            <li>
							<Link to="/sistemlər" >{looptech.translate("systems")}</Link> 
                            </li>
                            <li>
							<Link 
							 onClick={scrollToProjects}
							>{looptech.translate("projects")}</Link>
                            </li>
                            <li>
							<Link to="haqqımızda">{looptech.translate("about")}</Link>
                            </li>
                    </ul>

					<ul className="adress-call">
						<li>17 Sabit Orujov Street,Baku <span className='location-icon'><HiOutlineLocationMarker/> </span> </li>
                                <a
                                    className="call"
                                    href="tel:(+994) 70 288 08 88"
                                >
                                    (+994) 70 288 08 88 <span className='call-icon'><BsTelephone/></span>
                                </a>
                          
                    </ul>

				</div>
				</div>

				<hr className='line' />
				<div className='footer-down'>
				<p>© 2023 Bütün hüquqlar qorunur</p>
				<ul className='social'>
				<li><a href="#"><img src="/assets/social-icons/ins.png" alt="burada ins logosu var" /></a></li>
				<li><a href="#"><img src="/assets/social-icons/wp.png" alt="burada wp logosu var" /></a></li>
				<li><a href="#"><img src="/assets/social-icons/face.png" alt="burada fb logosu var" /></a></li>
				</ul>	
				</div>
			</div>
		</div>
	</section>
	  
	</>
  )
}

export default Footer
