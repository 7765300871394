import React from 'react'
// style css import
import "./style.scss"

function NotFound() {

  return (
	<>
	  <section className='not-found-section'>
		<div className="container">
			<div className='not-found'>
				<img src="/assets/404.svg" alt='burada 404 not found sekli var'/>
				<a href="/">ANA SƏHİFƏ </a>
			</div>  
		</div>
	  </section>
	</>
  )
}

export default NotFound
