export const mapStyle = [
    { elementType: "geometry", stylers: [{ color: "#DADADA" }] },
    { elementType: "labels.text.stroke", stylers: [{ color: "##CDFF64" }] },
    { elementType: "labels.text.fill", stylers: [{ color: "#746855" }] },
    {
      featureType: "administrative.locality",
      elementType: "labels.text.fill",
      stylers: [{ color: "#d59563" }],
    },
      {// iconlarin rengi
        elementType: "labels.icon",
        stylers: [{  color: "#414141"}],
      },
      {
        featureType: "administrative.land_parcel",
        elementType: "labels.text.fill",   
        stylers: [{ color: "#bdbdbd" }],
      },
      {
             // yazilarin rengi
        elementType: "labels.text.fill",
        stylers: [{ color: "#131313" }],
      },
      {
        featureType: "poi.park",
        elementType: "geometry",
        stylers: [{ color: "#e5e5e5" }],
      },
      {
         // suyun rengi
        featureType: "water",
        elementType: "geometry",
        stylers: [{ color: "#606060" }],
      }
]