import React from "react";
import "./style.css"
import { useEffect, useRef, useState } from "react";

const Iconlist = ({icon}) => {
	const [isIntersecting, setIsIntersecting] = useState(false);
	const ref = useRef(null);

	useEffect(() => {
		const observer = new IntersectionObserver(
			([entry]) => {
				setIsIntersecting(entry.isIntersecting);
			},
		);
		// console.log('first');
		observer.observe(ref.current);

		return () => observer.disconnect();
	}, [isIntersecting]);

	useEffect(() => {
		if (isIntersecting) {
			ref.current.querySelectorAll("svg").forEach((el) => {
				el.classList.add("active");
			});
		} else {
			ref.current.querySelectorAll("svg").forEach((el) => {
				el.classList.remove("active");
			});
		}
	}, [isIntersecting]);

	return (
		<div ref={ref}>

		{icon}
		
		
		</div>
	);
}

export default Iconlist;