import React, { memo, useCallback, useContext, useState } from "react";
import { Link } from "react-router-dom";
// import style scss
import "./style.scss";


// import react icon
import { RxHamburgerMenu } from 'react-icons/rx'; 
import { AiOutlineClose } from 'react-icons/ai'; 

import { GlobalContext } from "../components/useContext";
import looptech from "../helpers/looptech";



export  const scrollToProjects =()=>{
    const projectSectionElement =  document.getElementById("projects");
    if(!projectSectionElement){
        sessionStorage.setItem("scrollToProjects","true");
       return window.location.href ="/";
    }
    projectSectionElement.scrollIntoView({behavior:"smooth",block:"start"})
}




function NavBar() {   

    // useContext
    const {changeLanguage,lang} = useContext(GlobalContext)    

    // window scroll change navbar function
    const [navbar, setNavbar] = useState(false);
    const [showHamburgerMenu ,setshowHamburgerMenu] = useState(true)
    const [mobilMenu,setMobilMenu]=useState(false)

    // hamburger menyuya click olunanda menyunun deyismesi ucun funsksiya
    const showMenu=()=>{
        setshowHamburgerMenu(!showHamburgerMenu)
        setMobilMenu(!mobilMenu)
    }

    // scroll zamani navbarin renginin deyismesi funcsiyas
    function scrollNavbarChange() {
        if (window.scrollY > 10) {
            setNavbar(true);
        } else {
            setNavbar(false);
        }
    }

    window.addEventListener("scroll", scrollNavbarChange);
  
    const handleLanguage = useCallback(({target:{value}})=>{
        changeLanguage(value)
    },[])
    
    return (
        <>
            <section
                className={
                    navbar ? "navbar-section nav-blue" : "navbar-section"
                }
            >
                <div className="container">
                    <nav>
                        <div className="logo">
                            <a className="nav-logo" href="/">
                                <img
                                    src={
                                        navbar
                                            ? "/assets/NTlogo&location/NT-Logo-White.png"
                                            : "/assets/NTlogo&location/NT-Logo-Blue.svg"
                                    }
                                    alt="burada NT logosu var"
                                />    
                            </a>
                        </div>
   
                        <ul className="pages">
                             <li>
                                <a href="/" >{looptech.translate("home")}</a>  
                            </li>
                            <li>
                                <Link to="/sistemlər" >{looptech.translate("systems")}</Link>  
                            </li>
                            <li>
                                <Link
                                 onClick={scrollToProjects}
                                >{looptech.translate("projects")}</Link>
                            </li>
                            <li>
                                <Link to="haqqımızda">{looptech.translate("about")}</Link>
                            </li>
                        </ul>

                   <div className="call-email-language">
                        <ul className="call-email">
                            <li>
                                <a
                                    className="call"
                                    href="tel:(+994) 70 288 08 88"
                                >
                                    (+994) 70 288 08 88
                                </a>
                            </li>
                            <li>
                                <a
                                    className="email"
                                    href="mailto:Info@ntengineering.az?subject = Feedback&body = Message"
                                >
                                   Info@ntengineering.az
                                </a>
                            </li>
                        </ul>
                        <form >
                                <select onChange={handleLanguage} value={lang} className="change-language" id="">
                                    <option value="az">AZ</option>
                                    <option value="en">EN</option>
                                    <option value="ru">RU</option>
                                </select>
                        </form>
                        <div className="hamburger-menu" onClick={showMenu}>
                       { showHamburgerMenu ? <RxHamburgerMenu/> : <AiOutlineClose/>}
                        </div>
                            </div>

                    </nav>
                   
                    <ul className={ mobilMenu ? "mobil-menu-pages":"mobil-menu-pages noactive"}>
                                     <li onClick={showMenu}>
                                <a href="/" >{looptech.translate("home")}</a>  
                                    </li>

                            <li onClick={showMenu}>
                                <Link to="/sistemlər">{looptech.translate("systems")}</Link>     
                            </li>
                            <li onClick={showMenu}>
                                <Link 
                                 onClick={scrollToProjects}
                                >{looptech.translate("projects")}</Link>
                            </li>
                            <li onClick={showMenu}>
                                <Link to="/haqqımızda">{looptech.translate("about")}</Link>
                            </li>
                    </ul>
                    </div>
            </section>
        </>
    );
}

export default memo(NavBar);
