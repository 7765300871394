import React from "react";
// import icons
import { AiOutlineFilePdf } from "react-icons/ai";

// import sytle css
import "./style.scss";

function DownloadeComponent() {
    return (
        <>
            <a href="/assets/brochure.pdf" target="_blank" className="download-pdf" download>
                <h4>Broşürü yükləyin</h4>
                <button className="btn">
                    <AiOutlineFilePdf className="pdf-icon" />
                    <span className="download">Yükləyin</span>  
                </button>
            </a>
        </>
    );
}

export default DownloadeComponent;
