// import style css
import "./style.scss";
import React, { useEffect, useMemo, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { SystemsData } from "../../siteDatas";
import DownloadeComponent from "../../components/downloadComponent";
// import icons 
// import { AiOutlineFilePdf } from 'react-icons/ai';





function Systems() {


    const { slug } = useParams();
    const [active, setActive] = useState(slug);  

    useEffect(() => {
        if (!slug) setActive(SystemsData?.[0]?.slug);
    }, [JSON.stringify(SystemsData), slug]);

    
    const handleSelectedSystem = (system, slug) => {
        return system.find((item) => item.slug === slug) || {};
    };
    

    const selectedData = useMemo(
        () => handleSelectedSystem(SystemsData, active),
        [JSON.stringify(SystemsData), active]
    );

    return (
        <>
            <section className="systems-section">
                <div className="container">
                    <div className="systems">
                        <div className="systems-list-download">
                        <div className="systems-list">
                            <h4>SİSTEMLƏR</h4>  
                   
                            {SystemsData.map((item) => {
                                const systemSlug = item.slug;
      
                                return (
                                    <Link
                                        key={item.id}
                                        to={`/sistemlər/${systemSlug}`}
                                        onClick={() => setActive(systemSlug)}
                                        className={`${
                                            active === systemSlug
                                                ? "list-a active"
                                                : "list-a"
                                        }`}
                                    >
                                        <div className="icon-title">
                                            <span className="icon">
                                                {item.icon}
                                            </span>
                                            <span className="title">
                                                {item.title}
                                            </span>
                                        </div>
                                    </Link>
                                );
                            })}
                               </div>
                            <DownloadeComponent/>  
                        </div>
                        <div className="description">
                            <img src={selectedData.image} alt={selectedData.title}/>
                            <h4>{selectedData.title}</h4>
                            <p style={{whiteSpace:"pre-line"}}>{selectedData.body}</p>
                        </div>
                    </div>     
                </div>
            </section>
        </>
    );
}

export default Systems;
