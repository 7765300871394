// import style scss
import "./style.scss"
// import data
// import {MainSectionSliderDatas} from "../../siteDatas/index"
import { SystemsData } from '../../siteDatas';
import { Link } from "react-router-dom";

import React, {useContext } from "react";
import { GlobalContext } from "../useContext";

import Slider from "react-slick";

import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import looptech from "../../helpers/looptech";

// import { useContext } from "react";
// import { GlobalContext } from "../components/useContext";
// import looptech from "../helpers/looptech";


// export default class MainSectionSlider extends Component {
 

//   render() {
//     const settings = {
//       dots: false,
// 	  focusOnSelect: true,
//       infinite: true,
//       speed: 5000,
//       slidesToShow: 1,
//       slidesToScroll: 1,   
// 	  autoplaySpeed: 3500,
// 	  cssEase: "linear",
// 	  autoplay: true,      
// 	  pauseOnHover: false
//     };
//     return (
//       <div className="slider-section">        
// 		<div className="container">  
// 		<Slider {...settings}>
// 			{
// 				SystemsData.map(item=>(
// 					<div className="slider-div"  key={item.id}>
// 					<Link to={`/sistemlər/${item.slug}`}  className="info">
// 						<h3>{item.title}</h3> 
// 						<p>{item.description} ...</p>
// 						<button className="detailet-btn">Ətraflı</button>
// 					</Link>
//                   <div className="image">
// 				  <img src={item.image} alt="burada avadanliq sekli var" />
// 			  </div>
// 			  </div>   
// 				))
// 			}
//         </Slider>

// 		{/* old data map */}
//         {/* <Slider {...settings}>
// 			{
// 				MainSectionSliderDatas.map(item=>(
// 					<div className="slider-div"  key={item.id}>
// 					<div className="info">
// 						<h3>{item.header}</h3>
// 						<p>{item.description}</p>
// 						<button className="detailet-btn">Ətraflı</button>
// 					</div>
//                   <div className="image">
// 				  <img src={item.image} alt="burada avadanliq sekli var" />
// 			  </div>
// 			  </div>   
// 				))
// 			}
//         </Slider> */}
//       </div>
// 	  </div>
//     );
//   }
// }
// ==================================================================



function MainSectionSlider() {
	  // useContext
	const {changeLanguage,lang} = useContext(GlobalContext) 

	const settings = {
		dots: false,
		focusOnSelect: true,
		infinite: true,
		speed: 5000,
		slidesToShow: 1,
		slidesToScroll: 1,   
		autoplaySpeed: 3500,
		cssEase: "linear",
		autoplay: true,      
		pauseOnHover: false
	  };

	  return (
		<div className="slider-section">        
		  <div className="container">  
		  <Slider {...settings}>
			  {
				  SystemsData.map(item=>(
					  <div className="slider-div"  key={item.id}>
					  <Link to={`/sistemlər/${item.slug}`}  className="info">
						  <h3>{item.title}</h3> 
						  <p>{item.description} ...</p>
						  <button className="detailet-btn">{looptech.translate("more")}</button>
					  </Link>
					<div className="image">
					<img src={item.image} alt="burada avadanliq sekli var" />
				</div>
				</div>   
				  ))
			  }
		  </Slider>
		</div>
		</div>
	  );
}

export default MainSectionSlider



