import React, { useState } from 'react';
import "./style.css"
// react icons import
import { BsArrowLeft, BsArrowRight } from 'react-icons/bs';
const SLIDE_WIDTH = 400;
// const SLIDE_HEIGHT = 300;


let scrollTimeout;
const Slider = ({ items }) => {
  const [activeIndex, setActiveIndex] = useState(3);         

  // const autoScroll=()=>{
  //   scrollTimeout= setInterval(()=>{
  //     setActiveIndex(activeIndex=>activeIndex === items.length - 1 ? 0 : activeIndex + 1);
  // },2000)
  // }
  // 
  const handlePrev = () => {
    clearInterval(scrollTimeout)
    setActiveIndex(activeIndex === 0 ? items.length - 1 : activeIndex - 1);
    // autoScroll()
  };
  
  const handleNext = () => {
    // clearInterval(scrollTimeout)
    setActiveIndex(activeIndex === items.length - 1 ? 0 : activeIndex + 1);
    // autoScroll()
  };

  
  
// useEffect(()=>{
//   autoScroll()
//   return()=>clearInterval(scrollTimeout)
// },[JSON.stringify(items)])
  

  const renderSlides = () => {
    return items.map((item, index) => {
      const distanceFromCenter = Math.abs(index - activeIndex);
      const scale = Math.max(1 - distanceFromCenter * 0.8, 0.5);
      const opacity = Math.max(1 - distanceFromCenter * 0.5, 0.3);
      const transform = `translateX(${(index - activeIndex) * SLIDE_WIDTH}px) scale(${scale})`;

      return (
        <div
          key={item.id}
          className={`slide ${index === activeIndex ? 'active' : ''}`}
          style={{ transform, opacity }}
        >
          <img src={item.image} alt={item.description} />
          
          <div className="caption">{item.description}</div>     
        </div>
      );
    });
  };

  return (
      <>
    <div  className="slider">
      <div className="slides">{renderSlides()}</div>
    </div>
    <div className='arrow'>
      <span className="prev" onClick={handlePrev}>
          <BsArrowLeft/>
      </span>
      <span className="next" onClick={handleNext}>
        <BsArrowRight/>
      </span>
      </div>    
    
    </>
  );
};

export default Slider;