import React from 'react'
// import style scss
import "./style.scss"
import Iconlist from '../../iconMove'
import { Link } from 'react-router-dom';
import { SystemsData } from '../../siteDatas';
// import { GlobalContext } from '../useContext';


function OurWork () {
//   const {name}=useContext(GlobalContext)  

	return (   
	<>
	  <div className='our-work-section'>
		<div className='container'>
			<div className='header-description'>
				<h2>Biz yüksək keyfiyyətli iş təmin edirik</h2>
				<p className='header-descriptin'>Isitmə və soyutma sistemləri, bir çox müştəri üçün ev 
					və ya iş yerində rahatlığı təmin etmək üçün əsas sistemlərdən biridir.
					 Bu nəticədə, isitmə və soyutma sistemləri ilə təmin edərək müştərilərin 
					 tələblərini qarşılayan effektiv və sərfəli sistemlər təqdim
					 edərək böyük bir ticarət sahəsində fəaliyyət göstəririk.</p>
			</div>

         {/* <h1>{name}</h1> */}
            
	 <ul  className='works'>     

	 {
		SystemsData.map(item=>{
			return(
				<li key={item.id}  className='work-sistem-about'>
				<Link to={`/sistemlər/${item.slug}`} className='work-sistems'>
					<div className='sistem-svg'>  
					<Iconlist icon={item.movingIcon}/>
					</div>
					<h3>{item.title}</h3>
					<p className='sistems-description'>{item.body.slice(0,70)} ... </p>  
				</Link>    
				</li>
			)
		})
	 }
	          </ul>

			</div>
           </div>   
</>
)
 }
export default OurWork

