
import { createContext, useEffect, useState } from "react";
import looptech from "../../helpers/looptech";



export const GlobalContext=createContext();



export const GlobalProvider=(props)=>{
	
	const [lang,setLang] = useState("az");

	const changeLanguage = (lang)=>{
		looptech.language = lang;    
		localStorage.setItem("language",lang)
		setLang(lang)
	}
	

	useEffect(()=>{
		const defaultLang = localStorage.getItem("language") || "az";
		changeLanguage(defaultLang);
	},[])
	

	
	
	return(
		<GlobalContext.Provider
		 value={{lang, changeLanguage}}  
		 >
			{props.children}
		</GlobalContext.Provider>
	)
}