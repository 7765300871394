import React from 'react'
// import section datas
import { AboutSectionDatasAbout,AboutSectionDatasBG} from '../../siteDatas'

import DownloadeComponent from "../../components/downloadComponent";

// import style css
import "./style.scss"
import MapConnect from '../../components/mapConnect';


function About() {

  return (
	<>
	  <section className='about-section'>
		<div className="container">
		<div className="about-download">

			<div className="download">
				<h3>HAQQIMIZDA</h3>
				<DownloadeComponent/>
			</div>
         
            <div className='about'>
			<div className='about-content'>
			   {
				AboutSectionDatasAbout.map(item=>(
					<div className='about-header'>
						<div className='about-header-image'>
						<img src={item.image} alt="burada NT logo var" />
						</div>
						 <h2>{item.title}</h2>
						 <p>{item.body}</p>
					</div>
				))
			   }
			</div>

             <div className='about-activity'>
			{
			AboutSectionDatasBG.map(item=>(
			   	<div key={item.id}>
					 <h4>{item.title}</h4>
					  <p>{item.description}</p>
				  </div>
			))
		    }
			</div>

			<div className='license'>
				<div className='license-image'>
		         <img src={require(`../../assets/aboutSectionBGimage&NTlicense/lisenziya.png`)} alt="burada lisenziya sekli var" />
			     </div>
			</div>
           <div className='map-connect'>
		    <MapConnect/>
			</div>
		</div>

		</div>
		</div>
	  </section>
	</>
  )
}

export default About
