import React, { Component } from "react";
// style scss import
import "./style.scss";
// import Datas
import { projects } from "../../siteDatas/index";

// import slick slider
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
// react icons import
import { BsArrowLeft, BsArrowRight } from 'react-icons/bs';

// import { Link } from "react-router-dom";   
      
// function    Projects() {
//     const settings = {
//         dots: false,
//         infinite: true,
//         slidesToShow: 4.2,   
//         slidesToScroll: 1,        
//         responsive: [
//             {
//                 breakpoint: 1920,      
//                 settings: {   
//                     slidesToShow: 5.3,                     
//                 },
//             },
//             {
//                 breakpoint: 1720,
//                 settings: {
//                     slidesToShow: 4.3,                
//                 },
//             },      
//             {
//                 breakpoint: 1500,
//                 settings: {   
//                     slidesToShow: 3.3,   
//                 },
//             },      
//             {
//                 breakpoint: 1100,
//                 settings: {
//                     slidesToShow: 3.4,         
//                 },
//             },
//             {
//                 breakpoint: 950,
//                 settings: {
//                     slidesToShow: 4.2,
//                 },
//             },
//             {
//                 breakpoint: 800,
//                 settings: {  
//                     slidesToShow: 3.2,                             
//                 },
//             },
//             {
//                 breakpoint: 650,
//                 settings: {
//                     slidesToShow: 2.2,  
//                 },
//             },
//             {
//                 breakpoint: 480,       
//                 settings: {
//                     slidesToShow: 1.1,       
//                 },
//             },
//             {
//                 breakpoint: 350,
//                 settings: {   
//                     slidesToShow: 1.1,
//                 },
//             },
//         ],
//     };
//     return (
//         <section id="projects" className="projects-section">
//             <div className="container">
//                 <div className="all-project-see">
//                     <h3>Layihələrimiz</h3>
//                     <p>
//                         Sizin bizimlə işləməyiniz, bizim şirkətinizin inkişafına
//                         və uğuruna kömək edəcək və biz sizin inşaat materialları
//                         ilə bağlı tələblərinizi qarşılayacağıq.
//                     </p>
                  
//                 </div>

//                 <div className="slider-slick-part">
//                     <Slider {...settings}>
//                         {projects.map((item) => (
//                             <div className="project" key={item.id}>   
//                                 <div className="project-img">
//                                     {item.image && (
//                                         <img
//                                             src={item.image && item.image}
//                                             alt="project sekilleri var"
//                                         />
//                                     )}
//                                     <div className="overlay">
//                                         <h4>{item.info && item.info}</h4>
//                                     </div>
//                                 </div>
//                             </div>
//                         ))}
//                     </Slider>    
//                 </div>
//             </div>
//         </section>
//     );
// }

// export default Projects;

// ==========================================================
export default class Projects extends Component {
    constructor(props) {
      super(props);
      this.next = this.next.bind(this);
      this.previous = this.previous.bind(this);
    }
    next() {
      this.slider.slickNext();
    }
    previous() {
      this.slider.slickPrev();
    }
    render() {
      const settings = {
        dots: false,
        infinite: true,
        slidesToShow: 4.2,   
        slidesToScroll: 1,        
        responsive: [
            {
                breakpoint: 1920,      
                settings: {   
                    slidesToShow: 5.3,                     
                },
            },
            {
                breakpoint: 1720,
                settings: {
                    slidesToShow: 4.3,                
                },
            },      
            {
                breakpoint: 1500,
                settings: {   
                    slidesToShow: 3.3,   
                },
            },      
            {
                breakpoint: 1100,
                settings: {
                    slidesToShow: 3.4,         
                },
            },
            {
                breakpoint: 950,
                settings: {
                    slidesToShow: 4.2,
                },
            },
            {
                breakpoint: 800,
                settings: {  
                    slidesToShow: 3.2,                             
                },
            },
            {
                breakpoint: 650,
                settings: {
                    slidesToShow: 2.2,  
                },
            },
            {
                breakpoint: 480,       
                settings: {
                    slidesToShow: 1.1,       
                },
            },
            {
                breakpoint: 350,
                settings: {   
                    slidesToShow: 1.1,
                },
            },
        ],
      };
      return (

        <section id="projects" className="projects-section">
        <div className="container">
            <div className="all-project-see">
                  <h3>Layihələrimiz</h3>
                    {/* <p>
                        Sizin bizimlə işləməyiniz, bizim şirkətinizin inkişafına
                        və uğuruna kömək edəcək və biz sizin inşaat materialları
                        ilə bağlı tələblərinizi qarşılayacağıq.
                    </p> */}
     
        </div>

        <div className="slider-slick-part">
        <Slider ref={c => (this.slider = c)} {...settings}>   
                        {projects.map((item) => (
                            <div className="project" key={item.id}>   
                                <div className="project-img">
                                    {item.image && (
                                        <img
                                            src={item.image && item.image}
                                            alt="project sekilleri var"
                                        />
                                    )}
                                    <div className="overlay">
                                        <h4>{item.info && item.info}</h4>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </Slider> 
          

        </div>
         </div>
            <div className='arrow'>   
             <span className="prev" onClick={this.previous}>
             <BsArrowLeft/>
             </span>
             <span className="next"onClick={this.next}>  
             <BsArrowRight/>
             </span>
           </div>
        </section>
      );
    }
  }