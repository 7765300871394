import React from 'react'
// import style scss
import "./style.scss"

// import with forms react icons 
import {HiOutlineLocationMarker } from 'react-icons/hi';
import {BsTelephone } from 'react-icons/bs';
import {TfiEmail } from 'react-icons/tfi';

// import google maps
import { GoogleMap ,useLoadScript ,MarkerF, } from '@react-google-maps/api'
import {mapStyle} from "../mapStyle"

// import formik 
import { useFormik } from 'formik';
import  validations from '../yupValidations/validations';



const mapContainerStyle={
	width:"100%",        
	height:"100%"
     }

const style ={
		styles:mapStyle
     }
const center={
	  lat:40.381073,    
	  lng:49.871070
}	  

	    const mapLocationBlue =require("../../assets/mapLocationBlue.png")


function MapConnect() {

	// form formik
	const {handleSubmit,handleChange,values,errors, touched, handleBlur,resetForm} = useFormik({
		initialValues: {
		  name: '',
		  surname: '',
		  email: '',
		  number: "",     
		},
		onSubmit: values => {
		  alert(JSON.stringify(values,null,2));  
		    resetForm();
		},
		validationSchema:validations,
	  })

	// google maps
	const {isLoaded} = useLoadScript({
		googleMapsApiKey :"AIzaSyCT64H5CoYSBWpiwM7e2ucBZqYtTC22Y7k"
	}) 
	
if(!isLoaded) return <div>Loaded....</div>
	  
		
  return (
	<>
	  <section className='mapConnect-section'>
		<div className='container'>
			<div className='map-connect'>       
			  <div className='connect'>
              <div className='contact-adress'>   
				<a href="tel:(+994) 70 288 08 88" className='call'>
					<span className="tel-icon" ><BsTelephone/></span>
					<h6 className="contact-text">Əlaqə nömrəmiz</h6>
					<span className="number">(+994) 70 288 08 88</span>
				</a>

              <a href="mailto:Info@ntengineering.az?subject = Feedback&body = Message" className='email'>
					<span className="email-icon" ><TfiEmail/></span>
					<h6 className="contact-text">Email</h6>
					<span className="nt-email">Info@ntengineering.az</span>
				</a>
				<a className='adress'>
					<span className='adress-icon'><HiOutlineLocationMarker/></span>
					<h6>Ünvanımız</h6>
					<span className='street'>17 Sabit Orujov Street,Baku </span>
				</a>


			  </div>
        <hr className='line-call-send' />
			  <div className='send-question'>
				<h3>Sualınız var</h3>

				<form onSubmit={handleSubmit}>
					<div className='name-surname'>

						<div className='name'>
						<label htmlFor='name'>Ad</label>
						<input
						 type="text"
						  placeholder='Ad'
						  name='name'
						  value={values.name}
						  onChange={handleChange}
						  onBlur={handleBlur}
						  />
			  {errors.name && touched.name && <div className='error'>{errors.name}</div>}
						</div>
					
						   
					<div className='surname'>  
					<label htmlFor="surname">Soyad</label>
					<input
					 type="text" 
					 placeholder='Soyad'
					 name='surname'
					 value={values.surname}
					 onBlur={handleBlur}
					 onChange={handleChange}
					 />
			{errors.surname && touched.surname && <div className='error'>{errors.surname}</div>}		 
					</div> 
					</div>


					<div className='email'>
					<label htmlFor="email">E-poçt ünvanı</label>
					<input
					 type="email"
					 placeholder='E-poçt ünvanı'
					 name='email'
					 value={values.email} 
					 onBlur={handleBlur}
					 onChange={handleChange}
					  />
			{errors.email && touched.email && <div className='error'>{errors.email}</div>}		  
                    </div>

					<div className='number'>
						<label htmlFor="number">Mobil nömrə</label>
						<input 
						type="number"
						placeholder='Mobil nömrə'
						name='number'
						value={values.number}
						onBlur={handleBlur}
						onChange={handleChange} 
						 />
			{errors.number && touched.number && <div className='error'>{errors.number}</div>}			 
					</div>

                   <button className='send-btn' type='onsubmit'>Göndər</button>
				</form>     
			  </div>
				</div>






	<div className='map'>
		 <GoogleMap 
		 zoom={17}    
		 center={center}
		 mapContainerStyle={mapContainerStyle}
         options={style}
		 >
         <MarkerF 
         icon={{
			url:mapLocationBlue,
			scaledSize: new window.google.maps.Size(63,78),
		 }}
         position={center}/>
		 </GoogleMap>
				</div>
			</div>
		</div>
	  </section>
	</>
  )
}

export default MapConnect
