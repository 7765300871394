import React from 'react'
import Slider from '../sliderComponent/Slider'
import {PartnyorsProductsSectionDatas} from '../../siteDatas'
import "./style.scss"



function PartnyorsProducts() {
  return (
    <>
   <section className='partnyors-products-section'>
	<div className='container'>
	<h4>MƏHSULLAR</h4>

		<Slider items={PartnyorsProductsSectionDatas} />    
    
	</div>	
	</section>
    </>
  )
}

export default PartnyorsProducts
