import React, { useEffect } from 'react'

// section import
import OurWork from '../../components/ourWorks'
import Projects from '../../components/projectsSection'
import MapConnect from '../../components/mapConnect'
import MainSectionSlider from '../../components/mainSectionSlider'
import PartnyorsProducts from '../../components/partnyorsProducts'
import PartnyorsLogo from '../../components/partnyorsLogo'
import { scrollToProjects } from '../../navBar';



function Home() {
  

  
  const checkScroll = ()=>{
    const hasScroll = sessionStorage.getItem("scrollToProjects");
    if(!hasScroll)return
    setTimeout(() => {
      scrollToProjects()
      sessionStorage.setItem("scrollToProjects","")
    }, 700);
  }
  
  
  useEffect(()=>{
    checkScroll()
  },[])
  
  return (
    <div>
      <MainSectionSlider/> 
      <OurWork/> 
      <Projects/>
      <PartnyorsProducts/>       
      <PartnyorsLogo/>
      <MapConnect/>
    </div>
  )
}

export default Home